import './voucher.scss';

import { IonCol, IonGrid, IonRow, IonText, IonTextarea, useIonViewDidEnter } from '@ionic/react';
import VoucherAmountSelector from 'application/pages/Vouchers/VoucherAmountSelector';
import VoucherOrderFooter from 'application/pages/Vouchers/VoucherOrderFooter';
import VoucherValueSelector, { availableVoucherValues } from 'application/pages/Vouchers/VoucherValueSelector';
import { useAuth } from 'application/state/AuthProvider';
import { useVoucherContext } from 'application/state/VoucherContext';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { useContextTranslation } from 'ui/translation';
import currencyFormat from 'ui/utils/currencyFormat';
import VoucherBackground from 'ui/theme/images/Voucher.jpg';
import currency from 'currency.js';

type Expanded = 'value' | 'amount' | undefined;

export default function Vouchers(): JSX.Element {
  const t = useContextTranslation('page.voucher');
  const {
    voucherAmount,
    voucherValue,
    setVoucherAmount,
    setVoucherValue,
    setMessage,
    message,
  } = useVoucherContext();

  const location = useLocation();

  useIonViewDidEnter(() => {
      const urlParams = new URLSearchParams(location.search);

      const voucherValueParam = urlParams.get('voucherValue');
      const voucherAmountParam = urlParams.get('voucherAmount');
      const voucherMessageParam = urlParams.get('voucherMessage');

      if (voucherValueParam && availableVoucherValues.includes(parseInt(voucherValueParam, 10))) {
        setVoucherValue(currency(voucherValueParam));
      }
      if (voucherAmountParam && parseInt(voucherAmountParam, 10) <= 5) {
        setVoucherAmount(parseInt(voucherAmountParam, 10));
      }
      if (voucherMessageParam) {
        setMessage(voucherMessageParam);
      }
    },
  );

  const [expanded, setExpanded] = useState<Expanded>();
  const { isAuthenticated } = useAuth();
  const history = useHistory();

  const footerContent = !!voucherValue && (
    <VoucherOrderFooter
      displayContent={!!(voucherValue && voucherAmount)}
      nextEnabled={!!(voucherValue && voucherAmount && message.length <= 180)}
      onNext={() =>
        history.push(
          isAuthenticated ? '/vouchers/payment' : '/vouchers/contact',
        )
      }
    />
  );

  return (
    <SubPageLayout
      className="voucher-selector"
      footerContent={() => footerContent}
      beforeContent={
        <div className="voucher-gallery-header">
          <img className="default-img" src={VoucherBackground} alt="Voucher" />
        </div>
      }
    >
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <IonText>
              <h5 className="voucher-header">{t('header')}</h5>
              <p className="list-title">{t('list.title')}</p>
              <ul className="list">
                <li>{t('list.christmas')}</li>
                <li>{t('list.birthday')}</li>
                <li>{t('list.anniversary')}</li>
                <li>{t('list.mother_days')}</li>
                <li>{t('list.valentines_day')}</li>
              </ul>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <VoucherValueSelector
              expanded={expanded === 'value'}
              selectedValue={voucherValue?.value.toString()}
              onSelect={(e) => {
                setVoucherValue(currency(e.target.value));
                setExpanded(undefined);
              }}
              expandSection={() => setExpanded('value')}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <VoucherAmountSelector
              expanded={expanded === 'amount'}
              selectedNumber={voucherAmount}
              selectedValue={voucherValue && currencyFormat(voucherValue)}
              onSelect={(e) => {
                setVoucherAmount(
                  e.target.value ? parseInt(e.target.value, 10) : 0,
                );
                setExpanded(undefined);
              }}
              expandSection={() => setExpanded('amount')}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonTextarea
              inputMode="text"
              className="form-input"
              placeholder={t('message_placeholder')}
              rows={6}
              value={message}
              onIonChange={(e) => setMessage(e.detail.value || '')}
            />
            {message.length > 180 && (
              <div className="form-error">{t('message_too_long')}</div>
            )}
          </IonCol>
        </IonRow>
        <IonRow className="voucher-note">
          <IonCol>
            <p>{t('note')}</p>
          </IonCol>
        </IonRow>
      </IonGrid>
    </SubPageLayout>
  );
}
